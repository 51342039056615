<template>
  <div
    class="row"
  >
    <div class="col-sm-8">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Help text</label>
        <textarea
          v-model="block.help_text"
          cols="30"
          rows="2"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-sm-4">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Required</label>
        <div class="form-check">
          <input
            id="inlineCheckbox2"
            v-model="block.required"
            class="form-check-input"
            type="checkbox"
            value="unchecked"
          >
          <label
            class="form-check-label"
            for="inlineCheckbox2"
          >Yes</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  async mounted() {},
  methods: {
  },
}
</script>
