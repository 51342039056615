<template>
  <div
    class="row"
  >
    <div class="col-sm-8">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Help text</label>
        <textarea
          v-model="block.help_text"
          cols="30"
          rows="2"
          class="form-control"
        />
      </div>
      <h4>Options</h4>
      <draggable
        v-model="block.options"
        group="favor"
        @start="drag=true"
        @end="drag=false"
      >
        <li
          v-for="option, index in block.options"
          :key="option.order"
          class="draggable"
          style="list-style-type: none;"
        >
          <div class="row d-flex align-items-end">
            <div class="col-9">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Option {{ index + 1 }}</label>
                <input
                  v-model="option.option"
                  type="text"
                  :disabled="option.other"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-1">
              <div class="mb-1">
                <a
                  class="btn"
                  :class="{'bg-light-success': option.correct, 'bg-light-secondary': !option.correct}"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Mark as correct"
                  @click="option.correct = !option.correct"
                ><i data-feather="check" /></a>
              </div>
            </div>
            <div class="col-1">
              <div class="mb-1">
                <a
                  class="btn bg-light-danger"
                  @click="deleteOption(index)"
                ><i data-feather="trash" /></a>
              </div>
            </div>
            <div class="col-1">
              <div class="mb-1">
                <a
                  class="btn bg-light-secondary"
                ><i data-feather="move" /></a>
              </div>
            </div>
          </div>
        </li>
      </draggable>
      <div class="mb-2">
        <a
          class="btn btn-sm bg-light-secondary"
          @click="addOption(false)"
        ><i data-feather="plus" /> Add option</a> or
        <a
          class="btn btn-sm bg-light-secondary"
          @click="addOption(true)"
        ><i data-feather="edit-3" /> Add "other"</a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Required</label>
        <div class="form-check">
          <input
            id="inlineCheckbox2"
            v-model="block.required"
            class="form-check-input"
            type="checkbox"
            value="unchecked"
          >
          <label
            class="form-check-label"
            for="inlineCheckbox2"
          >Yes</label>
        </div>
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Type of answers</label>
        <div class="form-check mb-1">
          <input
            :id="`inlineRadio1-${random}-1`"
            v-model="block.answer_type"
            class="form-check-input"
            type="radio"
            :name="`inlineRadioOptions-${random}`"
            :value="false"
            checked="checked"
          >
          <label
            class="form-check-label"
            :for="`inlineRadio1-${random}-1`"
          >Single answer</label>
        </div>
        <div class="form-check mb-1">
          <input
            :id="`inlineRadio1-${random}-2`"
            v-model="block.answer_type"
            class="form-check-input"
            type="radio"
            :name="`inlineRadioOptions-${random}`"
            :value="true"
          >
          <label
            class="form-check-label"
            :for="`inlineRadio1-${random}-2`"
          >Multiple answers</label>
        </div>
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Design</label>
        <div class="form-check mb-1">
          <input
            :id="`inlineRadioOptions2-${random}-1`"
            v-model="block.design"
            class="form-check-input"
            type="radio"
            :name="`inlineRadioOptions2-${random}`"
            :value="false"
            checked="checked"
          >
          <label
            class="form-check-label"
            :for="`inlineRadioOptions2-${random}-1`"
          >View all answers</label>
        </div>
        <div class="form-check mb-1">
          <input
            :id="`inlineRadioOptions2-${random}-2`"
            v-model="block.design"
            class="form-check-input"
            type="radio"
            :name="`inlineRadioOptions2-${random}`"
            :value="true"
          >
          <label
            class="form-check-label"
            :for="`inlineRadioOptions2-${random}-2`"
          >View in select</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable,
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
      random: String(Math.floor(Math.random() * 10) + 1),
    }
  },
  computed: {
    currentOrder() {
      let maxOrder = 0

      this.block.options.forEach(e => {
        if (e.order > maxOrder) {
          maxOrder = e.order
        }
      })

      return maxOrder
    },
  },
  mounted() {
    this.resetFeather()
  },
  methods: {
    addOption(other) {
      this.block.options.push({
        option: other ? 'Other...' : '',
        correct: false,
        other,
        order: this.currentOrder + 1,
      })
      this.resetFeather()
    },
    deleteOption(index) {
      this.block.options.splice(index, 1)
      this.resetFeather()
    },
    resetFeather() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
