<template>
  <div
    class="row"
  >
    <div class="col-sm-8">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Help text</label>
        <textarea
          v-model="block.help_text"
          cols="30"
          rows="2"
          class="form-control"
        />
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >From</label>
            <select
              id=""
              v-model="block.from"
              name=""
              class="form-select"
            >
              <option value="0">
                0
              </option>
              <option value="1">
                1
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >To</label>
            <select
              id=""
              v-model="block.to"
              name=""
              class="form-select"
            >
              <option value="2">
                2
              </option>
              <option value="3">
                3
              </option>
              <option value="4">
                4
              </option>
              <option value="5">
                5
              </option>
              <option value="6">
                6
              </option>
              <option value="7">
                7
              </option>
              <option value="8">
                8
              </option>
              <option value="9">
                9
              </option>
              <option value="10">
                10
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Initial value</label>
            <input
              v-model="block.initial_value"
              type="text"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-6">
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Final value</label>
            <input
              v-model="block.final_value"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Required</label>
        <div class="form-check">
          <input
            id="inlineCheckbox2"
            v-model="block.required"
            class="form-check-input"
            type="checkbox"
            value="unchecked"
          >
          <label
            class="form-check-label"
            for="inlineCheckbox2"
          >Yes</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  async mounted() {},
  methods: {
  },
}
</script>
