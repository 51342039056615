<template>
  <div
    class="row"
  >
    <div class="col-sm-8">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Help text</label>
        <textarea
          v-model="block.help_text"
          cols="30"
          rows="2"
          class="form-control"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Upload file</label>
        <div
          class="input-group custom-file-button"
        >
          <label
            class="input-group-text"
            for="inputGroupFile"
          >Select file</label>
          <input
            id="formFileImage"
            :ref="`file-download-${block.order}`"
            class="form-control"
            type="file"
            @change="handleFileChange"
          >
        </div>
      </div>
      <div
        v-if="url"
        class="mb-1"
      >
        <a
          :href="url"
          target="_blank"
        ><i data-feather="eye" /> See file</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
      url: null,
    }
  },
  mounted() {
    if (this.block.file) {
      this.url = this.block.file.url
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        // Read file and set URL
        const reader = new FileReader()
        reader.onload = e => {
          this.url = e.target.result
        }
        reader.readAsDataURL(file)

        const fileData = {
          name: file.name,
          original_name: file.name,
          description: 'Image to web site',
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          to: '',
          file,
        }

        this.$set(this.block, 'file_download', fileData)
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)
      } else {
        // No file selected
        this.url = ''
      }
    },
    deleteFile() {
      this.block.file_download = null
      this.block.file = null
      this.url = ''
    },
  },
}
</script>
