<template>
  <div
    class="row"
  >
    <div class="col-sm-8">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Help text</label>
        <textarea
          id=""
          v-model="block.help_text"
          name=""
          cols="30"
          rows="2"
          class="form-control"
        />
      </div>
      <div
        v-if="!block.file_multimedia && !block.file"
        class="mb-1"
      >
        <label
          for=""
          class="form-label"
        >File <em>jpg, png, gif, audio, video</em></label>
        <div
          class="input-group custom-file-button"
        >
          <label
            class="input-group-text"
            for="inputGroupFile"
          >Select file</label>
          <input
            id="formFileImage"
            :ref="`file-multimedia-${block.order}`"
            class="form-control"
            type="file"
            @change="handleFileChange"
          >
        </div>
      </div>
      <!-- Conditional rendering based on the file type -->
      <div v-if="fileType === 'audio'">
        <!-- Audio player -->
        <audio
          controls
          :src="fileURL"
        >
          Your browser does not support the audio element.
        </audio>
      </div>

      <div v-else-if="fileType === 'video'">
        <!-- Video player -->
        <video
          style="width: 100%;"
          controls
          :src="fileURL"
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div v-else-if="fileType === 'image'">
        <!-- Image display -->
        <img
          :src="fileURL"
          alt="Image"
        >
      </div>

      <div v-else>
        <!-- Default message when no file is selected -->
        Please select a file.
      </div>
      <div
        v-if="block.file_multimedia || block.file"
        class="mb-1"
      >
        <!-- también puede ser un audio o video -->
        <div class="mt-1">
          <a
            class="text-danger"
            @click="deleteFile"
          ><i data-feather="trash" /> Delete file</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Required</label>
        <div class="form-check">
          <input
            id="inlineCheckbox2"
            v-model="block.required"
            class="form-check-input"
            type="checkbox"
            value="unchecked"
          >
          <label
            class="form-check-label"
            for="inlineCheckbox2"
          >Yes</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
      fileType: '',
      fileURL: '',
    }
  },
  mounted() {
    if (this.block.file) {
      this.fileType = this.block.file.extension
      this.fileURL = this.block.file.url
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        // Determine file type
        if (file.type.startsWith('audio')) {
          this.fileType = 'audio'
        } else if (file.type.startsWith('video')) {
          this.fileType = 'video'
        } else if (file.type.startsWith('image')) {
          this.fileType = 'image'
        } else {
          // Unsupported file type
          this.fileType = ''
          return
        }
        // Read file and set URL
        const reader = new FileReader()
        reader.onload = e => {
          this.fileURL = e.target.result
        }
        reader.readAsDataURL(file)

        const fileData = {
          name: file.name,
          original_name: file.name,
          description: 'Image to web site',
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: this.fileType,
          created_at: 'Pending to save...',
          to: '',
          file,
        }

        this.$set(this.block, 'file_multimedia', fileData)
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)
      } else {
        // No file selected
        this.fileType = ''
        this.fileURL = ''
      }
    },
    deleteFile() {
      this.block.file_multimedia = null
      this.block.file = null
      this.fileType = ''
      this.fileURL = ''
    },
  },
}
</script>
