<template>
  <div class="block-form">
    <a
      v-b-toggle="`collapseBlock-${block.id}${block.type}`"
      :class="`btn btn-sm btn-${block.relation && block.relation.block.title ? 'success' : 'secondary' } dis-ib`"
    >
      <i data-feather="corner-left-up" /> {{ block.relation && block.relation.block.title ? 'With' : 'Add' }} relationship
    </a>
    <b-collapse
      :id="`collapseBlock-${block.id}${block.type}`"
    >
      <div
        class="mt-3"
      >
        <div class="card shadow-none bg-transparent border-secondary">
          <div class="card-body">
            <h3>Add relationship</h3>
            <div class="row d-flex align-items-end">
              <div class="col-sm-6">
                <div class="mb-1">
                  <label
                    for="formFile"
                    class="form-label"
                  >Select form element</label>
                  <v-select
                    v-model="block.relation.block"
                    label="title"
                    :options="selects"
                    :get-option-key="option => option.title"
                  />
                </div>
              </div>
              <div class="col-sm-5">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Condition</label>
                  <v-select
                    v-model="block.relation.option"
                    label="option"
                    :options="block.relation.block.options"
                    :get-option-key="option => option.option"
                  />
                </div>
              </div>
              <div class="col-sm-1">
                <div class="mb-1">
                  <a
                    href=""
                    class="btn bg-light-danger"
                  ><i data-feather="trash-2" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
      selectedOne: [],
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      form: 'requestForms/item',
    }),
    selects() {
      return this.form.blocks.filter(e => e.type.toLowerCase() === 'several_options')
    },
  },
  mounted() {},
}
</script>
