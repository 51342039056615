<template>
  <div
    class="row"
  >
    <div class="col-12">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Title</label>
        <input
          v-model="block.title"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <div class="col">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Text</label>
        <input
          v-model="block.name"
          type="text"
          class="form-control"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  async mounted() {},
  methods: {
  },
}
</script>
